import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Error from './commons/Error';

const UserDetailsContainer = lazy(() => import('./user-details/UserDetailsContainer'));
const SSOContainer = lazy(() => import('./sso/SSOContainer'));

const ForgotPassword = lazy(() => import('./sso/ForgotPassword'));
const Authorize = lazy(() => import('./sso/Authorize'));
const ResetPassword = lazy(() => import('./sso/ResetPassword'));
const PrivacyPolicy = lazy(() => import('./sso/PrivacyPolicy'));

const App = () => (
  <Suspense fallback={<div></div>}>
    <Switch>
      <Redirect exact from="/" to="/session/login" />
      <Route path="/user" component={UserDetailsContainer} />
      <Route path="/session" component={SSOContainer} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/api/oauth/authorize" component={Authorize} />
      <Route path="/reset-password/:token" component={ResetPassword} />
      <Route path="/user-notice" component={PrivacyPolicy} />
      <Route path="/*" component={Error} />
    </Switch>
  </Suspense>
);

export default App;
