function Error() {
  const [status, error] = window.params ? [window.params.status, window.params.error] : [404, 'Resource not found.'];
  return (
    <div>
      <div className="boxed-container boxed-container-large">
        <div className="margin-top-large">
          {status} {error}
        </div>
      </div>
    </div>
  );
}

export default Error;
